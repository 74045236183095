import React from 'react'
import Layout from "../layouts/index";
import i18next from 'i18next';
import { graphql } from 'gatsby';

class NotFoundPage extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
      }
  }

  render() {
      const data = this.props.data;
      return(
          <Layout children={null} data={data}>
              <div className="main404">
                <h1>{i18next.t("404_NOT_FOUND")}</h1>
                <p>{i18next.t("404_MSG")}</p>
              </div>
          </Layout>
      )
  }
}

/* CategoryQuery */
export const query = graphql`
query ($locale: String!) {
  categories: allDatoCmsCategory(sort: {order: ASC, fields: sequence}, filter: {top: {eq: true}, locale: {eq: $locale}}) {
    edges {
      node {
        id
        name
        url
        sequence
        locale
        subcategory {
          id
          name
          url
          locale
          sequence
          subcategory {
            id
            name
            url
            locale
            sequence
          }
        }
      }
    }
  }
  contentPages: allDatoCmsContentPage(filter: {locale: {eq: $locale}}) {
      edges {
          node {
              locale
              url
              cpContentGridTitle
          }
      }
  }
  allDatoCmsProduct(filter: {locale: {eq: $locale}}, sort: {fields: modelo}) {
      edges {
          node {
              id
              descripcion
              descripcionampliada
              categoria
              categorias {
                  id
                  name
                  url
                  locale
                  sequence
              }
              marca
              modelo
              colorcrist
              colormont
              forma
              material
              sexo
              polarizacion
              calibre
              puente
              varilla
              idarticulo
              images
              weight
              offerpriceeur
              offerpricegbp
              offerpriceusd
              listpriceeur
              listpricegbp
              listpriceusd
              crosssell
              meta {
                createdAt
              }
          }
      }
  }
  allDatoCmsFooter(filter: {locale: {eq: $locale}}) {
    edges {
      node {
          footerLinksList
          footerCopyright
          footerPayments
      }
    }
  }
  contactinfo : datoCmsExtrainfo(name: {eq: "contactinfo"}, locale: {eq: $locale}) {
      data
  }
  basemarketing : datoCmsExtrainfo(name: {eq: "plp-basicdata"}, locale: {eq: $locale}) {
      text
  }
  ddm : allDatoCmsDdm(filter: {locale: {eq: $locale}}) {
      nodes {
          submenu {
              header
              directLink
              menu
          }
      }
  }
}
`


export default NotFoundPage
